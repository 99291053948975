// ContactMe.js

import React from 'react';
import './Contact.css';

const ContactMe = () => {
    return (
        <div className="container about-me">
            {/* <h1 className="container">Contact Me</h1> */}
            <p className="container">If you have any questions or need to get in touch, you can reach me at:<a href="edaisyma@gmail.com">edaisyma@gmail.com</a></p>
        </div >
    );
};

export default ContactMe;
