// LandingPage.js
import React from 'react';

const LandingPage = () => {
    return (
        <div className='container'>
            <h1>TBD</h1>
            {/* Add your landing page content here */}
        </div>
    );
};

export default LandingPage;
